import * as React from "react"
import { StyleSheetManager } from "styled-components"
import rtlPlugin from "stylis-plugin-rtl"

import { GlobalStyles } from "../../global-styles"
import { Layout, P, SubTitle } from "../../components"
import { Description } from "../../blocks/description"
import { Header } from "../../blocks/header"
import { FashionGallery } from "../../blocks/fashion-gallery"
import { Explore } from "../../blocks/explore"
import { StayConnected } from "../../blocks/stay-connected"
import { CatwalkGallery } from "../../blocks/catwalk"
import { Brands } from "../../blocks/brands"
import { Logo } from "../../images"
import { Contacts, ContactInfo, Grid } from "../../blocks/contacts"
import SEO from "../../components/seo"

const App = () => {
  return (
    <StyleSheetManager disableVendorPrefixes stylisPlugins={[rtlPlugin]}>
      <Layout rtl>
        <SEO title="Leem" description="Modern fashion - redefined" />
        <GlobalStyles />
        <Header rtl>
          مفهوم جديد
          <br />
          للأزياء
          <br />
          المحتشمة
        </Header>
        <a name="about" />
        <Description
          contentTop="علامة ليم السعودية! مفهوم جديد للأزياء المحتشمة. منذ عام 2018، أبدعنا العديد من تشكيلات الموضة المحتشمة والراقية بلمسة عصرية نالت إعجاب أبرز المجلات مثل Harper's Bazaar, Elle, Vogue و Emirates Woman.
"
          contentBottom="يمكنكم تسوق أرقى منتجات الموضة المحتشمة بأسعار معقولة عبر متاجر ليم المنتشرة في جميع أنحاء المملكة العربية السعودية والإمارات العربية المتحدة والبحرين. أو يمكنكم الطلب عبر تطبيق ليم في المملكة العربية السعودية, و سيتم توفير خدمة الشحن و التوصيل إلى جميع أنحاء العالم قريباً.
"
        />
        <a name="collection" />
        <FashionGallery
          description="أقمشة رائعة . تصاميم أنيقة . قصات مثالية"
          rtl
        >
          المفهوم الحقيقي
          <br />
          للازياء المحتشمه
        </FashionGallery>
        <CatwalkGallery
          description="تشكيلات عصرية يمكن تنسيقها مع بعضها لتعكس شخصيتكم.​"
          rtl
        >
          موضة محتشمة
          <br />
          مستوحاة من منصات عروض الأزياء
        </CatwalkGallery>
        <Brands />
        <a name="press" />
        <a name="app" />
        <Explore
          header="استكشفي وتسوقي من ليم"
          description="الآن يمكنك تحميل تطبيق ليم للإطلاع على أحدث التشكيلات و الإصدارات الجديدة"
        />
        <StayConnected header="للتواصل" rtl />
        <a name="stores" />
        <Contacts header="تجدونا في المواقع التالية">
          <Grid>
            <div>
              {/* Riyadh, KSA */}
              <SubTitle data-size="m">
                الرياض، المملكة العربية السعودية
              </SubTitle>
              <P>
                حياة مول،
                <br />
                الطابق الأرضي، البوابة #7
              </P>
              <P>
                الرياض بارك مول،
                <br />
                الطابق الأول، البوابة #2
              </P>
              <P>
                بانوراما مول،
                <br />
                البوابة #2
              </P>
            </div>
            <div>
              {/* Jeddah, KSA */}
              <SubTitle data-size="m">جدة، المملكة العربية السعودية</SubTitle>
              <P>
                مول البحر الأحمر،
                <br />
                الطابق الأرضي، البوابة #3
              </P>
            </div>
            <div>
              {/* Dubai, UAE */}
              <SubTitle data-size="m">دبي، الإمارات العربية المتحدة</SubTitle>
              <P>
                مردف سيتي سنتر،
                <br />
                الطابق الأرضي، البوابة #A
              </P>
              <P>
                ميركاتو مول،
                <br />
                الطابق الأرضي، البوابة #2
              </P>
            </div>
            <div>
              {/* Manama, Bahrain */}
              <SubTitle data-size="m">المنامة، البحرين</SubTitle>
              <P>
                مول سيتي سنتر البحرين،
                <br />
                الطابق الثاني، البوابة #8
              </P>
            </div>
          </Grid>
          <ContactInfo>
            <div>
              <P>customercare@leem.com, +966 800 440 0099</P>
              <P>Riyadh, Saudi Arabia</P>
            </div>
            <Logo />
          </ContactInfo>
        </Contacts>
      </Layout>
    </StyleSheetManager>
  )
}

export default App
